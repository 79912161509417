/* roboto-mono-300 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/roboto-mono-v22-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-mono-v22-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-mono-v22-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-mono-v22-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-mono-v22-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/roboto-mono-v22-latin-300.svg#RobotoMono") format("svg"); /* Legacy iOS */
}
/* roboto-mono-regular - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/roboto-mono-v22-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-mono-v22-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-mono-v22-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/roboto-mono-v22-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-mono-v22-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/roboto-mono-v22-latin-regular.svg#RobotoMono") format("svg"); /* Legacy iOS */
}
/* roboto-mono-500 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/roboto-mono-v22-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-mono-v22-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-mono-v22-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-mono-v22-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-mono-v22-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/roboto-mono-v22-latin-500.svg#RobotoMono") format("svg"); /* Legacy iOS */
}
/* roboto-mono-600 - latin */
@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/roboto-mono-v22-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/roboto-mono-v22-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/roboto-mono-v22-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/roboto-mono-v22-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/roboto-mono-v22-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/roboto-mono-v22-latin-600.svg#RobotoMono") format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(232, 250, 250);
  /* color: rgb(255, 174, 160); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
